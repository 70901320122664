import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { PageContainer, PageHeading2 } from "../../globalStyles";
import {
  ContentContainer,
  NavContainer,
  NavControls,
  UpdatesList,
  UpdateItem,
  ProgressBar,
} from "./UpdatesPageStyle";

function UpdatesPage() {
  let navigate = useNavigate();

  const { theme, toggleTheme } = useContext(ThemeContext);

  const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed

  const handleHomeClick = (event) => {
    event.preventDefault();
    navigate(`/`);
  };

  const Spacer = ({ width }) => (
    <span style={{ display: "inline-block", width }}>&nbsp;</span>
  );
  return (
    <PageContainer>
      <NavContainer theme={theme} className={`NavContainer`}>
        <NavControls className="NavControls">
          <h2 onClick={handleHomeClick}>
            <u>
              {/* <FontAwesomeIcon icon={faHouse} /> */}
              Mahābhārata
            </u>
          </h2>
        </NavControls>
      </NavContainer>

      <ContentContainer className={`ContentContainer navClosed`}>
        <PageHeading2>Translation Progress</PageHeading2>
        <UpdatesList>
          <UpdateItem>
            <p>
              <b>Nov 28 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk05ch064
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩▣············]</ProgressBar>33.3%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Nov 16 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk05ch037
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩▣············]</ProgressBar>32.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Oct 29 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk05ch011
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩▣············]</ProgressBar>30.7%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Oct 23 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk04ch067
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩·············]</ProgressBar>30.2%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Oct 18 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk04ch045
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩·············]</ProgressBar>29.4%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Oct 06 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk04ch020
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▩·············]</ProgressBar>28.4%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Sep 26 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch299
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▣·············]</ProgressBar>27.7%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Sep 21 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch278
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▣·············]</ProgressBar>26.5%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Sep 14 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch253
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩▣·············]</ProgressBar>25.3%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Sep 05 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch230
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩··············]</ProgressBar>24.5%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Sep 01 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch219
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩··············]</ProgressBar>24.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Aug 23 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch198
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▩··············]</ProgressBar>23.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Aug 18 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch186
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▣··············]</ProgressBar>22.2%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Aug 08 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch161
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▣··············]</ProgressBar>21.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jul 31 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch144
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩▣··············]</ProgressBar>20.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jul 28 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch126
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩···············]</ProgressBar>19.3%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jul 20 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch104
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩···············]</ProgressBar>18.5%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jul 10 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch076
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▩···············]</ProgressBar>17.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jul 05 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch053
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▣···············]</ProgressBar>16.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 30 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch033
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▣···············]</ProgressBar>15.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 25 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk03ch012
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩▣···············]</ProgressBar>14.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 21 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk02ch072
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩················]</ProgressBar>13.4%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 20 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk02ch062
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩················]</ProgressBar>13.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 10 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk02ch042
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▩················]</ProgressBar>12.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jun 05 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk02ch018
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▣················]</ProgressBar>11.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>May 23 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch225
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▣················]</ProgressBar>10.1%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>May 17 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch204
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩▣················]</ProgressBar>9.2%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Apr 18 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch169
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩·················]</ProgressBar>8.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Apr 08 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch143
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩·················]</ProgressBar>7.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Mar 24 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch118
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▩·················]</ProgressBar>6.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Mar 10 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch096
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▣·················]</ProgressBar>5.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Mar 03 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch077
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▣·················]</ProgressBar>4.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Feb 23 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch060
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[▣·················]</ProgressBar>3.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Feb 11 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch042
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[··················]</ProgressBar>2.1%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Feb 04 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch012
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[··················]</ProgressBar>1.0%
            </p>
          </UpdateItem>
          <UpdateItem>
            <p>
              <b>Jan 28 2024</b>:<Spacer width="20px" />
              {isMobile ? <br></br> : <></>} bk01ch000
              <Spacer width="20px" />
              {isMobile ? <br></br> : <></>}Progress:{" "}
              <ProgressBar>[··················]</ProgressBar>0.0%
            </p>
          </UpdateItem>
        </UpdatesList>

        <p>
          A more detailed progress log is available{" "}
          <a href="https://x.com/termite_colony/status/1751550708761891155">
            here
          </a>
          .
        </p>
      </ContentContainer>
    </PageContainer>
  );
}

export default UpdatesPage;
