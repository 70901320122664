import React, { useContext } from "react";
import { ThemeContext } from "../../ThemeContext/ThemeContext";
import { Link } from "react-router-dom";
import { PageContainer, PageHeading2 } from "../../globalStyles";
import {
  BooksList,
  Book,
  ContentContainer,
  ButtonContainer,
  ChangeThemeButton,
} from "./HomePageStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";

function HomePage() {
  let books_list = [
    "01_Adiparvan.json",
    "02_Sabhaparvan.json",
    "03_Aranyakaparvan.json",
    "04_Virataparvan.json",
    "05_Udyogaparvan.json",
    "06_Bhismaparvan.json",
    "07_Dronaparvan.json",
    "08_Karnaparvan.json",
    "09_Salyaparvan.json",
    "10_Sauptikaparvan.json",
    "11_Striparvan.json",
    "12_Santiparvan.json",
    "13_Anusasanaparvan.json",
    "14_Asvamedhikaparvan.json",
    "15_Asramavasikaparvan.json",
    "16_Mausalaparvan.json",
    "17_Mahaprasthanikaparvan.json",
    "18_Svargarohanaparvan.json",
  ];

  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <PageContainer>
      <br></br>
      <ButtonContainer className="ButtonContainer">
        <ChangeThemeButton theme={theme} onClick={toggleTheme}>
          <FontAwesomeIcon icon={faLightbulb} />
        </ChangeThemeButton>
      </ButtonContainer>
      <PageHeading2>Mahābhārata</PageHeading2>
      <br></br>
      <ContentContainer>
        <p>
          The <b>Mahābhārata</b>, composed by the sage Vyāsa, is one of the
          major Sanskrit epics of ancient India. Narrating the events leading up
          to the Kurukṣetra War and the fates of the Kaurava and Pāṇḍava
          princes, the{" "}
          <a href="https://bori.ac.in/department/mahabharata/">BORI CE</a>{" "}
          consists of approximately 80,035 ślokas (verses) divided into 1885
          chapters across 18 parvas (books). These 18 books are listed below
          offering the BORI CE Saṃskṛta verses along with translations from{" "}
          <a href="https://padhegaindia.in/product/the-mahabharata-volume-1-10/">
            Bibek Debroy's corpus
          </a>
          .{" "}
        </p>
        <BooksList>
          {books_list.map((book, index) => (
            <Book key={index}>
              <Link to={"/book/" + book + "/1"}>
                {book.substring(3, book.length - 5)}
              </Link>
            </Book>
          ))}
        </BooksList>
        <p>
          <i>
            This website is <Link to={"/changelog/"}>under construction</Link>{" "}
            and the translations are still being{" "}
            <Link to={"/updates/"}>worked on</Link>. Please report any bugs or
            translation errors to{" "}
            <a href="https://x.com/termite_colony">@termite_colony</a>. Your
            feedback is highly appreciated.
          </i>
        </p>
        <p style={{ textAlign: "center" }}>
          This website is for educational purposes only. Please consider
          supporting the original creators for their work.
        </p>
      </ContentContainer>
    </PageContainer>
  );
}

export default HomePage;
